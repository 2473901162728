import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// components
import { useForm } from "react-hook-form";
import { userService } from "../../api/user";

import {
  Input,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Button,
} from "@material-tailwind/react";

import SlimHeader from "../../components/slimHeader";
import MainFooter from "../../components/mainFooter";
import LoginForm2025 from "../../components/loginForm2025";

export default function LoginView({ setUserToken, token, setToken }) {
  const [isDialogForgotPassword, setIsDialogForgotPassword] = useState(false);
  const [isSended, setIsSended] = useState(false);
  const [, /*email*/ setEmail] = useState();

  const { loginByToken } = useParams();

  const {
    register,
    handleSubmit,
    //formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    userService
      .recoveryPassword({ email: data?.email })
      .then((response) => {
        setIsSended(true);
      })
      .catch((error) => {});
  };

  const toggleDialogForgotPassword = () => {
    setIsDialogForgotPassword(!isDialogForgotPassword);
  };

  /** verify login by token */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    token = loginByToken;
  }, [loginByToken]);

  //--- ---

  return (
    <>
      <SlimHeader />

      <nav
        id="home"
        className="container-fluid p-0 bg-orange-50 sticky top-0 z-40">
        <div className="container mx-auto max-w-1xl py-3 border-bottom">
          <div className="flex flex-row justify-start sm:justify-center items-stretch pl-5">
            <a href="/" target="_self" className="w-1/2">
              <div className="text-left text-primaryLabMar font-sans gap-3 text-2xl flex flex-row items-center">
                <img
                  style={{ width: "64px", height: "64px" }}
                  src={"/images/logo.svg"}
                  alt={"haccpForma"}
                />
                {/*<div className="font-bold">HACCP FORMAZIONE</div>*/}
              </div>
            </a>
            <div className="w-1/2 text-right"></div>
          </div>
        </div>
      </nav>

      <div className="container-fluid p-0 bg-white min-h-96">
        <div className="container w-full mx-auto py-9 px-3 sm:px-6 p-0 border-bottom text-primaryLabMar text-md">
          <div>
            <div className="text-xl font-semibold mb-8">
              Accedi alla tua area riservata verifica i dati del tuo profilo.
              Potrai seguire le lezioni attive comodamente da remoto. Per
              accedere devi utilizzare email e password che hai inserito in fase
              di registrazione.
            </div>
            <LoginForm2025
              setUserToken={setUserToken}
              setToken={setToken}
              token={token || loginByToken || false}
              toggleDialogForgotPassword={toggleDialogForgotPassword}
            />
          </div>
        </div>
      </div>

      <MainFooter />

      <Dialog
        open={isDialogForgotPassword}
        handler={toggleDialogForgotPassword}
        size={"lg"}>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full p-2 md:p-4">
          <DialogHeader>
            <span className="cursor-pointer">Hai dimenticato la password?</span>
          </DialogHeader>
          <DialogBody>
            {!isSended ? (
              <input
                label="Email"
                type="email"
                placeholder="Email"
                className="text-md font-normal appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+$/i,
                  onChange: (e) => setEmail(e.target.value),
                })}
              />
            ) : (
              <p className="green">
                Abbiamo inviato la password alla tua email.
              </p>
            )}
          </DialogBody>
          <DialogFooter>
            <Button
              className="bg-primaryLabMar"
              onClick={toggleDialogForgotPassword}>
              Chiudi
            </Button>
            {!isSended && (
              <Button
                className="ml-2"
                variant="gradient"
                color="green"
                type="submit">
                <span>Invia</span>
              </Button>
            )}
          </DialogFooter>
        </form>
      </Dialog>
    </>
  );
}
