import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { formatDate } from "../../../utils/date";

export default function UserTutorDetail(props) {
  const [, /*profileData*/ setProfileData] = useState({});
  const [activeCourse, setActiveCourse] = useState(false);
  const [, /*cartData*/ setCartData] = useState({});
  const [cartDataList, setCartDataList] = useState([]);
  const [courses, setCourses] = useState([]);
  const [certificateData, setCertificateData] = useState([]);

  const courseRef = React.createRef();

  const loadRemote = () => {
    try {
      fetch(
        `${process.env.REACT_APP_API_V2_URL}/myprofile/loadbyid?userId=${props.userId}`
      )
        .then((response) => response.json())
        .then((data) => {
          setProfileData(data.payload);
          setActiveCourse(data?.payload?.activeCourse || false);
          setCartDataList(data?.payload?.cartData || []);
          setCertificateData(data?.payload?.certificateData || []);

          if (
            (data?.payload?.cartData || []).filter((item) => {
              return item.isActive === 0;
            }).length > 0
          ) {
            setCartData(
              (data?.payload?.cartData || []).filter((item) => {
                return item.isActive === 0;
              })[0]
            );
          }
        })
        .catch((error) => {
          console.error("Errore nel recuperare dati profilo:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const loadRemoteCourseList = (props) => {
    try {
      fetch(`${process.env.REACT_APP_API_V1_URL}/trainingOffer/courses`)
        .then((response) => response.json())
        .then((data) => {
          const courses = data?.payload?.courses || [];

          Promise.all(
            courses.map((course) => getPriceByCourseCode(course.code))
          )
            .then((prices) => {
              const updatedCourses = courses.map((course, index) => {
                course.price = prices[index];
                return course;
              });

              setCourses(updatedCourses);
            })
            .catch((error) => {
              console.error("Errore nel recuperare il prezzo:", error);
            });
        })
        .catch((error) => {
          console.error("Errore nel recuperare i corsi:", error);
        });
    } catch (error) {
      console.error("Error:", error);
      // toast.error("This didn't work.")
    }
  };

  const getPriceByCourseCode = (courseCode = "") => {
    return new Promise((resolve, reject) => {
      const currentDomain = 'www.haccpforma.it';//window.location.origin.replace(/^https?:\/\//, "");
      const externalEndpoint =
        process.env.REACT_APP_API_V1_URL + "/dashboard/pricelist/";
      const urlWithDomainParam = `${externalEndpoint}?domain=${encodeURIComponent(
        currentDomain
      )}`;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      };

      fetch(urlWithDomainParam, requestOptions)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Errore nella richiesta Fetch");
          }
          return response.json();
        })
        .then((data) => {
          const priceList = (data?.payload?.data || []).filter(
            (item) => item.courseCode === courseCode
          );

          let price = 0;
          let iva = 0;

          if (priceList[0]?.price) {
            price =
              parseFloat(priceList[0]?.price) > 0
                ? parseFloat(priceList[0]?.price)
                : 0;
          } else {
            price =
              parseFloat(priceList[0]?.priceCourse) > 0
                ? parseFloat(priceList[0]?.priceCourse)
                : 0;
          }

          iva = 0; //(price * parseFloat(priceList[0]?.iva)) / 100;
          price = parseFloat(price + iva).toFixed(2);

          resolve(price);
        })
        .catch((error) => {
          console.error("Si è verificato un errore:", error);
          reject(error);
        });
    });
  };

  const confirm = () => {
    const course = courseRef.current.value;

    if (course === "0") {
      alert("Seleziona un corso.");
      return;
    }

    const token = localStorage.getItem("token");
    const domain = props.domain;
    assignCourseToStudentByTutor(
      course.split("|")[0],
      domain,
      token,
      course.split("|")[1]
    );
  };

  //--- ---
  const assignCourseToStudentByTutor = (
    courseCode = "",
    domain = "",
    token,
    courseId = 0
  ) => {
    const obj2Send = {
      courseCode,
      courseId: parseInt(courseId),
      domain,
      token,
      studentId: props.userId || 0,
    };

    try {
      fetch(
        `${process.env.REACT_APP_API_V2_URL}/cart/assign-course-student-by-tutor`,
        {
          method: "POST",
          body: JSON.stringify(obj2Send),
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data?.status === 422) {
            toast.error(data.message);
          } else if (data?.status === 200) {
            toast.success("Corso assegnato con successo.");
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }; //add2Cart
  //--- ---

  useEffect(() => {
    if (props.currentSubInfo === props.sub) {
      loadRemoteCourseList();
      loadRemote();
    }
    // eslint-disable-next-line
  }, [props.currentSubInfo]);

  //--- ---

  return (
    <>
      <div className="container flex flex-nowrap items-stretch mb-4 gap-3">
        <div className="p-4 bg-white rounded-xl w-1/2">
          <p className="mb-4">
            <label htmlFor="course" className="text-md font-semibold m-2">
              seleziona un corso ed assegnalo al corsista:
            </label>
            <select
              ref={courseRef}
              className="w-full text-md font-normal mt-3 p-2 border border-gray-300 rounded-lg cursor-pointer"
              name="course"
              id="course">
              <option value="0">Corsi Disponibili:</option>
              {courses
                .filter((item) => {
                  return item.isActive === 1;
                })
                .map((course, index) => {
                  return (
                    <option key={index} value={course.code + "|" + course.id}>
                      {course.title}
                    </option>
                  );
                })}
            </select>
            {activeCourse === false ? (
              <Button
                variant="gradient"
                color="green"
                size="lg"
                onClick={() => confirm()}
                className="mt-3 py-2 px-4">
                <span>CONFERMA</span>
              </Button>
            ) : (
              <div className="mt-2 text-sm text-red-900">
                Per assegnare un corso deve prima essere completato quello
                attivo.
              </div>
            )}
          </p>
          <hr className="my-3 mx-auto bg-blue-600" />
          <p className="text-lg font-bold mb-2">Corsi attivi:</p>
          {(cartDataList || [])
            .filter((items) => {
              return items.isActive === 1 && items.statusUC === "inProgress";
            })
            .map((item, index) => {
              return (
                <div key={index} className="mb-2">
                  <span className="text-md font-semibold">
                    {item.title} - ({item.registrationCode})
                  </span>
                </div>
              );
            })}

          {(cartDataList || []).filter((items) => {
            return items.isActive === 1 && items.statusUC === "inProgress";
          }).length === 0 ? (
            <div className="mt-2 text-sm text-red-900">
              Non ci sono Corsi attivi per questo corsista.
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="p-4 bg-white rounded-xl w-1/2">
          <p className="text-lg font-bold mb-4">Certificati emessi:</p>

          {certificateData.length === 0 ? (
            <div className="mt-2 text-sm text-red-900">
              Non ci sono certificati emessi per questo corsista.
            </div>
          ) : (
            ""
          )}

          {certificateData.map((item, index) => {
            return (
              <div key={index} className="mb-2 border-b border-gray-300 pb-2">
                <div className="text-md">
                  <b>{item.courseTitle}</b> - <b>progressivo:</b>{" "}
                  {item.progress}/{item.aa}
                  <br />
                  <b>emesso il:</b> {formatDate(item.releasedAt)}
                  <br />
                  <b>scade il:</b> {formatDate(item.expiredAt)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
