import { useState, useContext, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { userService } from "../../api/user";
import { ROUTES } from "../../routing/routes";

function LoginForm2025({
  setUserToken,
  setToken,
  token,
  toggleDialogForgotPassword,
}) {
  const location = useLocation();
  const domainUrl = new URLSearchParams(location.search).get("domain");
  let domainFrom = process.env.REACT_APP_DOMAIN;

  if (domainUrl !== null) {
    domainFrom = domainUrl;
  }

  const [, /*user*/ setUser] = useContext(UserContext);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    // let domain = window.location.host;
    // let object = { ...data, domain };

    await loginUser({
      domain: domainFrom,
      email,
      password,
    });
  };

  useEffect(() => {
    loginByToken(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const loginByToken = (token) => {
    if (token === "" || token === false) {
      return true;
    }

    userService
      .getUser({ token })
      .then((response) => {
        const role = response?.data?.payload[0].role || "";
        setUser(response?.data?.payload[0]);
        setToken(token);
        setUserToken(true);
        setUser(response.data.payload);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);

        switch (role) {
          case "student":
            navigate(ROUTES.COURSES);
            break;
          case "admin":
            navigate("/admin/users/student");
            break;
          case "inspector":
            navigate("/admin/user-log");
            break;
          case "tutor":
            navigate(ROUTES.PRICELIST);
            break;
          default:
            break;
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.message);
      });
  };

  async function loginUser(credentials) {
    const params = {
      ...credentials,
    };

    userService
      .login(params)
      .then(function (response) {
        const token = response.data.payload.token;
        const role = response.data.payload.role;

        //TODO ripristinare dopo test

        /*if (role !== "admin" && response?.data?.payload?.domain !== "*") {
          if (domainUrl == null || domainUrl === null || domainUrl === "null") {
            setTimeout(() => {
              window.location.replace(
                `https://${response?.data?.payload?.domain}`
              );
            }, 500);
            return false;
          }

          if (response?.data?.payload?.domain !== domainUrl.replace("/", "")) {
            //alert('Accesso non autorizzato. Se il problema persiste contatta il servizio assistenza.');
            setTimeout(() => {
              window.location.replace(
                `https://${response?.data?.payload?.domain}`
              );
            }, 500);
            return false;
          }
        }*/

        setToken(token);
        setUserToken(true);
        setUser(response.data.payload);
        localStorage.setItem("token", token);
        localStorage.setItem("role", role);

        switch (role) {
          case "student":
            navigate(ROUTES.COURSES);
            break;
          case "admin":
            navigate("/admin/users/student");
            break;
          case "tutor":
            navigate(ROUTES.PRICELIST);
            break;
          default:
            break;
        }
      })
      .catch(function (error) {
        setError(error?.response?.data?.message);
      });
  }

  let registerLink = "/register?domain=" + domainFrom;

  //--- ---

  return (
    <>
      <div className="text-xl font-semibold mt-8 mb-8">
        <div className="flex flex-col items-center justify-center px-6 mx-auto lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            {
              <>
                <div className="p-4 py-6">
                  <h1 className="flex items-center justify-center text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                    Accedi al tuo profilo personale
                  </h1>
                </div>

                <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8 hide">
                  <div className="w-full max-w-md space-y-8">
                    {error && (
                      <p className="text-red-900 text-center">{error}</p>
                    )}
                    <form
                      className="space-y-6"
                      onSubmit={handleSubmit(onSubmit)}>
                      <div className="w-full px-1 mb-2 sm:mb-4 md:mb-0">
                        <label
                          className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-first-name">
                          Email:
                        </label>
                        <input
                          label="Email"
                          type="email"
                          placeholder="Email"
                          className="text-md font-normal appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                            onChange: (e) => setEmail(e.target.value),
                          })}
                        />
                        {errors.email && (
                          <span className="text-red-500 text-xs italic">
                            Inserisci la tua email
                          </span>
                        )}
                      </div>

                      <div className="w-full px-1 mb-2 sm:mb-4 md:mb-0">
                        <label
                          className="rounded-lg py-3 pb-0 px-1 block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-first-name">
                          Password:
                        </label>
                        <input
                          type="password"
                          label="Password"
                          placeholder="Password"
                          className="text-md font-normal appearance-none block w-full text-gray-800 border border-primaryLabMar rounded-lg py-3 px-4 leading-tight"
                          {...register("password", {
                            required: true,
                            onChange: (e) => setPassword(e.target.value),
                          })}
                        />
                        {errors.password && (
                          <span className="text-red-500 text-xs italic">
                            Inserisci la tua password
                          </span>
                        )}
                      </div>

                      <button
                        type="submit"
                        className={`w-full mt-6 bg-primaryLabMar font-semibold text-md border border-transparent text-white p-4 py-2 rounded-full transition-colors duration-300 hover:bg-white hover:text-primaryLabMar hover:border hover:border-primaryLabMar`}>
                        ACCEDI
                      </button>

                      <div className="flex items-center justify-center">
                        <div
                          onClick={() => toggleDialogForgotPassword()}
                          className="text-md font-medium text-primary-600 hover:underline dark:text-primary-500 cursor-pointer">
                          password dimenticata?
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="mt-4 text-center text-white hide">
                  <p>
                    Non hai un account?{" "}
                    <Link to={registerLink} className="ml-1 underline">
                      Registrati
                    </Link>
                  </p>
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginForm2025;
