import { useState, useEffect, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { UserContext } from "../../context/userContext";

import { courseService } from "../../api/course";
import { userService } from "../../api/user";
import { getDomain } from "../../utils/domain";

import { Alert } from "@material-tailwind/react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

//-- endpoints

//-- components
import Layout from "../../components/layout";
import CourseCard from "../../components/courseCard";

export default function CoursesView() {
  let token = localStorage.getItem("token");
  const [user, setUser] = useContext(UserContext);

  let navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [activeCourse, setActiveCourse] = useState();
  const [currentCourse, setCurrentCourse] = useState([]);
  const [userCourse, setUserCourse] = useState();
  const [waitBtn, setWaitBtn] = useState(true);

  const [open, setOpen] = useState(false);

  const [, /*dataModal*/ setDataModal] = useState();

  let modalToOpen = "";

  function toggleModalCourseActive() {
    setOpen((prev) => !prev);
    setDataModal(modalToOpen);
    setWaitBtn((prev) => !prev);
  }

  // get data courses and active courses if exists
  const getData = () => {
    courseService
      .courses({ token })
      .then((response) => {
        setCourses(response?.data?.payload ? response.data.payload : []);
        if (response.data?.payload?.userCourses?.length) {
          const objActiveCourse = response.data?.payload?.userCourses.filter(
            (item) => item.status === "inProgress"
          );
          setActiveCourse(objActiveCourse[0]);
        }
      })
      .catch((error) => {});
  };

  const getUserCourseData = () => {
    const params = { token };
    courseService
      .getCourse({ params })
      .then((response) => {
        setActiveCourse(response?.data?.payload);
      })
      .catch((error) => {});
  };

  const geAllUserCourseData = () => {
    const params = { token };
    courseService
      .getAllCourseCourse({ params })
      .then((response) => {
        setUserCourse(response?.data?.payload[0]);
      })
      .catch((error) => {});
  };

  const getUser = () => {
    userService
      .getUser({ token })
      .then((response) => {
        setUser(response?.data?.payload[0]);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getData();
    getUserCourseData();
    geAllUserCourseData();
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCourses, setActiveCourse, setUserCourse, setUser]);

  //-- onClick button card course
  const handleCourse = (code) => {
    setWaitBtn((prev) => !prev);
    //-- set current course selected
    setCurrentCourse(courses.courses.find((e) => e.code === code));

    //-- if clicked is the same of active go direct to course page
    if (code === activeCourse?.courseCode) {
      navigate("/courses/course", { state: { course: code } });
    }

    //-- modal confirm
    modalToOpen = "modal-course-attive";
    toggleModalCourseActive();
  };

  const goToMyProfilePage = () => {
    const token = localStorage.getItem('token');
    localStorage.removeItem('token');
    window.location.href = "https://" + user?.domain+`/accedi?domain=${"https://" + user?.domain}&token=${token}`;
  };

  //-- start a new course
  const startCourse = (evt) => {
    // if exist an active course then overide information last active
    evt.preventDefault();

    //-- params course selected
    const params = {
      domain: getDomain(),
      token: token,
      courseCode: currentCourse?.code,
    };
    courseService
      .activeCourse(params)
      .then((response) => {
        navigate("/courses/course", { state: { course: currentCourse?.code } });
      })
      .catch((error) => {});
  };

  if (typeof user?.version === "undefined") {
    return true;
  }

  //--- ---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div
            className={`md:grid ${
              user?.version < 2 ? "grid-cols-2" : "grid-cols-1"
            } gap-4 mt-10`}>
            {courses.courses &&
              user?.version < 2 &&
              courses?.courses?.map((course, index) =>
                userCourse?.courseCode === course?.code &&
                userCourse?.endedAt !== null &&
                userCourse?.elapsedTime <= 10 ? (
                  <Card key={index} className="mt-20 md:mt-0 cursor-pointer">
                    <CardHeader className="relative h-56">
                      <img
                        src={
                          "/images/dashboard/courses/" + course?.code + ".jpeg"
                        }
                        alt="immagine"
                        className="w-full"
                      />
                    </CardHeader>
                    <CardBody>
                      <Typography variant="h5" className="mb-2">
                        {course?.title}
                      </Typography>
                    </CardBody>
                    <CardFooter
                      divider
                      className="flex items-center justify-between py-3">
                      <div>
                        <p className="font-bold text-green-900">
                          {"Corso Superato"}
                        </p>
                      </div>
                      {/* {userCourse?.isPayed === 0 ? ( */}

                      {user?.idParent > 0 &&
                        userCourse?.paymentTransactionID === null && (
                          <Button
                            className={`${"bg-green-600"} group relative flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                            onClick={() =>
                              navigate("/payment", {
                                state: {
                                  course: course,
                                },
                              })
                            }>
                            {"Prosegui per Ricevere il Certificato"}
                          </Button>
                        )}

                      {userCourse?.paymentTransactionID === null &&
                        user?.idParent === 0 && (
                          <Button
                            className={`${"bg-green-600"} group relative flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                            onClick={() =>
                              navigate("/payment", {
                                state: {
                                  course: course,
                                },
                              })
                            }>
                            {"Vai al pagamento"}
                          </Button>
                        )}

                      {userCourse?.paymentTransactionID !== null &&
                        parseInt(user?.idParent) === 0 && (
                          <a href="/certificates">
                            <Button
                              className={`${"bg-green-600"} group relative flex justify-center rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}>
                              {"Vai agli attestati"}
                            </Button>
                          </a>
                        )}
                    </CardFooter>
                  </Card>
                ) : (
                  (waitBtn) && (<div key={index} onClick={() => handleCourse(course.code)}>
                    <CourseCard
                      id={course.id}
                      idParent={parseInt(user?.idParent)}
                      code={course.code}
                      title={course.title}
                      userBonus={user?.userBonus}
                      buttonLabel={
                        course?.code === activeCourse?.courseCode
                          ? "Prosegui il corso"
                          : "Inizia il corso"
                      }
                      isActiveCourse={
                        course?.code === activeCourse?.courseCode ? true : false
                      }
                      userVersion={user?.version || 1}
                    />
                  </div>)
                )
              )}

            {courses.courses &&
              user?.version === 2 &&
              courses?.courses
                .filter((course) => {
                  return course?.code === activeCourse?.courseCode;
                })
                .map((course, index) =>
                  userCourse?.courseCode === course?.code &&
                  userCourse?.endedAt !== null &&
                  userCourse?.elapsedTime <= 10 ? (
                    "Si è verificato un errore. riprova con il login oppure contatta il centro Assistenza."
                  ) : (
                    (waitBtn) && <div key={index} onClick={() => handleCourse(course.code)}>
                      <CourseCard
                        id={course.id}
                        idParent={parseInt(user?.idParent)}
                        code={course.code}
                        title={course.title}
                        userBonus={user?.userBonus}
                        buttonLabel={"PROCEDI CON IL TUO CORSO"}
                        isActiveCourse={
                          course?.code === activeCourse?.courseCode
                            ? true
                            : false
                        }
                        userVersion={user?.version || 1}
                      />
                    </div>
                  )
                )}

            {courses.courses &&
              user?.version === 2 &&
              courses?.courses.filter((course) => {
                return course?.code === activeCourse?.courseCode;
              }).length === 0 && (
                <Alert
                  color="blue-gray"
                  className="text-center font-semibold text-xl">
                  In questo momento non hai corsi Attivi da seguire.
                  <br /> Puoi tornare alla pagina del tuo profilo personale per
                  tutte le informazioni necessarie.
                  <br />
                  <br />
                  <Button
                    color="green"
                    className="py-4 px-6 text-bold text-md"
                    onClick={() => goToMyProfilePage()}>
                    Torna al tuo profilo
                  </Button>
                </Alert>
              )}
          </div>
        </div>
      </Layout>

      <Dialog open={open} handler={toggleModalCourseActive} size="xl">
        <DialogHeader>Iniza il corso {currentCourse.title}</DialogHeader>
        <DialogBody divider>
          <div>
            <p>
              {"Il corso, compreso il pagamento, dovrà terminare entro"}
              <span className="font-bold ml-1">
                {currentCourse.maxTime / 24} giorni ({currentCourse.maxTime}{" "}
                ore)
              </span>
            </p>
          </div>
        </DialogBody>
        {activeCourse && currentCourse.code !== activeCourse.courseCode ? (
          <DialogBody divider>
            <div>
              <Alert color="red">
                <p className="text-xl font-semibold">
                  Puoi seguire solo un corso alla volta!
                </p>
                <p>
                  Questa nuova iscrizione sovrascriverà i tuoi progressi sul
                  corso già iniziato
                </p>

                {activeCourse.title}
              </Alert>
            </div>
          </DialogBody>
        ) : null}

        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={toggleModalCourseActive}
            className="mr-1">
            <span>Annulla</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={(evt) => startCourse(evt)}>
            <span>Inizia un nuovo corso</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
