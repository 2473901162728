import React, { useState, useEffect } from "react";

import { adminService } from "../../api/admin";

export default function CourseDetailView(props) {
  //const token = localStorage.getItem("token");
  const [certificateData, setCertificateData] = useState([]);

  //--- ---

  const loadRemote = () => {
    try {
      fetch(
        `${process.env.REACT_APP_API_V2_URL}/myprofile/loadbyid?userId=${props.userId}`
      )
        .then((response) => response.json())
        .then((data) => {

            console.log("data", data);

          setCertificateData(data?.payload?.certificateData || []);
        })
        .catch((error) => {
          console.error("Errore nel recuperare dati profilo:", error);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (date) => {
    if (date === null) {
      return true;
    }
    const dataString = date;
    const data = new Date(dataString);
    const day = String(data.getDate()).padStart(2, "0");
    const month = String(data.getMonth() + 1).padStart(2, "0");
    const year = String(data.getFullYear());

    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    loadRemote();
    //eslint-disable-next-line
  }, []);

  //--- ---

  return (
    <>
      <div className="container mx-auto">
        <div className="mb-2 font-semibold">Certificati emessi:</div>
        {certificateData.map((item, index) => {
          return (
            <div key={index} className="mb-2 border-b border-gray-300 pb-2">
              <div className="text-md">
                <b>{item.courseTitle}</b> - <b>progressivo:</b> {item.progress}/
                {item.aa}
                <br />
                <b>emesso il:</b> {formatDate(item.releasedAt)} - <b>scade il:</b> {formatDate(item.expiredAt)}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
