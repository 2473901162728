export function getCurrentPathLessons() {
  const date = new Date();
  const dayOfMonth = date.getDate();

  if (dayOfMonth <= 10) {
    return "https://www.italiahaccp.it";
  }

  if (dayOfMonth > 10 && dayOfMonth < 20) {
    return "https://app.labmar.it";
  }

  if (dayOfMonth >= 20) {
    return "https://www.haccpforma.it";
  }

  return "https://app.labmar.it";
}
