import React, { useState } from "react";
import Layout from "../../../components/layout";
import Datepicker from "react-tailwindcss-datepicker";
import Calendar from "./calendar";

export default function WeeklyCertificatesReportView() {
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const handleValueChange = (newValue) => {
    setValue(newValue);
  };

  //let token = localStorage.getItem("token");

  //---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div className="relative mb-4 hidden">
            <Datepicker
              i18n={"it"}
              startWeekOn="mon"
              primaryColor={"blue"}
              asSingle={true}
              useRange={false}
              placeholder={"Seleziona una data"}
              value={value}
              onChange={handleValueChange}
              showShortcuts={false}
              showFooter={false}
              displayFormat={"DD/MM/YYYY"}
              inputClassName="w-full p-4 pl-10 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
          </div>

          <div>
            <Calendar/>
          </div>
        </div>
      </Layout>
    </>
  );
}
