import React from "react";
import {
  FaPhone,
  FaEnvelope,
  FaWhatsapp
} from "react-icons/fa";

const SlimHeader = () => {
  return (
    <>
      <div id="home" className="container-fluid p-0 border-b border-gray-300">
        <div className="container mx-auto max-w-1xl py-1 border-bottom">
          <div className="flex flex-row items-center justify-center font-sans text-sm text-gray-600">
            <div className="w-1/2 text-lef">
              <FaPhone className="inline-block mr-2" /> +39 0925 442972{" "}
              <FaEnvelope className="inline-block mr-2" />
              assistenzalabmar@gmail.com
            </div>
            <div className="w-1/2 text-right">
            <FaPhone className="inline-block" /> / <FaWhatsapp className="inline-block mr-2" /> +39 391 401 9277
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SlimHeader;
