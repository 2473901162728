import { useRef, useState } from "react";
import Layout from "../../../components/layout";
import { adminService } from "../../../api/admin";
import moment from "moment";
import * as XLSX from "xlsx-with-styles";

export default function UserLog() {
  let token = localStorage.getItem("token");
  const [userLog, setUserLog] = useState([]);

  //const defaultSearch = useRef();
  const defaultFrom = useRef();
  const defaultTo = useRef();
  const defaultSearch = useRef();

  const goToSearch = (action) => {
    if (action === "toGrid") {
      loadUserLog(
        defaultSearch.current.value,
        defaultFrom.current.value,
        defaultTo.current.value,
        action
      );
    }

    if (action === "toDownload") {
      loadUserLog(
        defaultSearch.current.value,
        defaultFrom.current.value,
        defaultTo.current.value,
        action
      );
    }
  }; //goToSearch

  const loadUserLog = (
    defaultSearch = "",
    defaultFrom = "",
    defaultTo = "",
    action = "toGrid"
  ) => {
    let params = {
      token: token,
      //defaultSearch: defaultSearch,
      defaultFrom: defaultFrom,
      defaultTo: defaultTo,
      defaultSearch: defaultSearch,
    };

    setUserLog([]);

    adminService
      .getUserLog(params)
      .then((response) => {
        //--- ---
        if (action === "toDownload") {
          const filename =
            "reportUserLog_" +
            defaultFrom.replace(/-/g, "") +
            "_" +
            defaultTo.replace(/-/g, "") +
            ".xls";

          // Mappa e manipola i dati
          const data = (response?.data?.payload?.data || []).map((item) => {
            const clonedItem = { ...item };
            delete clonedItem.countAll;
            if (clonedItem.createdAt) {
              clonedItem.createdAt = moment(clonedItem.createdAt).format(
                "DD-MM-YYYY HH:mm:ss"
              );
            }
            return clonedItem;
          });

          const headers = ["NOME", "COGNOME", "IP", "DATA"];

          // Crea l'array di array a partire dalle intestazioni
          const aoaData = [headers, ...data.map((item) => Object.values(item))];

          // Crea un nuovo workbook e un nuovo foglio
          const wb = XLSX.utils.book_new();
          const ws = XLSX.utils.aoa_to_sheet(aoaData);

          // Calcola le larghezze delle colonne
          const colWidths = aoaData[0].map((col, colIndex) => ({
            wch:
              Math.max(
                ...aoaData.map((row) =>
                  row[colIndex] ? row[colIndex].toString().length : 0
                )
              ) + 3,
          }));
          ws["!cols"] = colWidths;

          // Funzione per applicare lo stile a una cella
          const applyCellStyle = (cell, style) => {
            cell.s = {
              ...cell.s,
              ...style,
            };
          };

          // Stile del bordo
          const borderStyle = {
            top: { style: "thin" },
            bottom: { style: "thin" },
            left: { style: "thin" },
            right: { style: "thin" },
          };

          // Applica il bordo a tutte le celle e centrare il contenuto della prima riga
          aoaData.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
              const cellAddress = XLSX.utils.encode_cell({
                r: rowIndex,
                c: colIndex,
              });
              if (!ws[cellAddress]) {
                ws[cellAddress] = { t: "s", v: cell };
              }
              applyCellStyle(ws[cellAddress], { border: borderStyle });
              if (rowIndex === 0) {
                applyCellStyle(ws[cellAddress], {
                  alignment: { horizontal: "center" },
                  fill: { fgColor: { rgb: "FFFF00" } }, // Colore giallo
                });
              }
            });
          });

          // Aggiungi il foglio al workbook
          XLSX.utils.book_append_sheet(wb, ws, "Foglio1");

          // Genera il file Excel come array buffer
          const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
          const blob = new Blob([wbout], { type: "application/octet-stream" });

          // Crea un link per forzare il download
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();

          // Rimuovi il link dopo il download
          document.body.removeChild(a);
          URL.revokeObjectURL(url);

          /*const csvContent =
        "data:text/csv;charset=utf-8," +
        "NOME, COGNOME, IP, DATA\n" + // CSV header
        (response?.data?.payload?.data || [])
          .map((item) => {
            const clonedItem = { ...item };
            delete clonedItem.countAll;
            clonedItem.createdAt = moment(clonedItem.createdAt).format("DD-MM-YYYY HH:mm:ss");
            return Object.values(clonedItem).join(",");
          })
          .join("\n");

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", filename);
      document.body.appendChild(link); // Required for Firefox
      link.click();*/
        }

        //--- ---
        if (action === "toGrid") {
          setUserLog(response?.data?.payload?.data || []);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }; //loadUsers

  //---

  return (
    <>
      <Layout>
        <div className="mx-auto">
          <div>
            <label
              htmlFor="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
              Search
            </label>
            <div className="relative flex flex-col md:flex-row gap-1.5">
              <input
                type="email"
                id="default-search"
                ref={defaultSearch}
                className="block w-full p-4 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="cognome / email"
              />

              <input
                type="date"
                id="default-from"
                ref={defaultFrom}
                className="block w-1/4 p-4 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="dal"
                defaultValue={moment().format("YYYY-MM-DD")}
              />

              <input
                type="date"
                id="default-to"
                ref={defaultTo}
                className="block w-1/4 p-4 text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="al"
                defaultValue={moment().add(1, "day").format("YYYY-MM-DD")}
              />

              <button
                type="submit"
                onClick={(e) => {
                  goToSearch("toGrid");
                }}
                className="block w-1/3 p-4 text-md rounded-lg uppercase text-white bg-blue-700 hover:bg-blue-800">
                genera report
              </button>

              <button
                type="submit"
                onClick={(e) => {
                  goToSearch("toDownload");
                }}
                className="block w-1/3 p-4 text-md rounded-lg uppercase text-white bg-blue-700 hover:bg-blue-800">
                download report
              </button>
            </div>
          </div>
        </div>

        <table className="w-full border-collapse border border-slate-500 text-left mt-4">
          <thead>
            <tr>
              <th className="border border-slate-600 p-2 text-center">Nome</th>
              <th className="border border-slate-600 p-2 text-center">
                Cognome
              </th>
              <th className="border border-slate-600 p-2 text-center">IP</th>
              <th className="border border-slate-600 p-2 text-center">Data</th>
            </tr>
          </thead>

          <tbody>
            {userLog.map((item, index) => {
              return (
                <tr key={index} className="even:bg-gray-70 odd:bg-white">
                  <td className="border border-slate-600 p-2">{item.name}</td>
                  <td className="border border-slate-600 p-2">
                    {item.surname}
                  </td>
                  <td className="border border-slate-600 p-2">{item.ipaddr}</td>
                  <td className="border border-slate-600 p-2 text-center">
                    {moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Layout>
    </>
  );
}
