import { userService } from "../api/user";

export function Logout(action = "") {
  let token = localStorage.getItem("token");

  const params = {
    token: token,
  };

  userService
    .logout({ params })
    .then((response) => {
      localStorage.setItem("token", "");

      if (action === "noredirect") {
        return true;
      }

      //--- ---

      if (response?.data?.domain === "*") {
        window.location.replace("/login");
      }

      if (
        response?.data?.domain !== "" &&
        response?.data?.domain !== "undefined" &&
        response?.data?.domain !== "null"
      ) {
        window.location.replace(`https://${response?.data?.domain}`);
      } else {
        window.location.replace("/login");
      }
    })
    .catch(function (error) {
      window.location.replace("/login");
    });
}
